body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* #header_logo_left {
    content: url("../public/header_logo.png");
    max-height: 45px;
  }
  
  #logo_left_side_top {
    content: url("../public/google_cloud_logo.png");
    max-height: 23px;
  }
  
  #logo_left_side_bottom {
    content: url("../public/google_palm_logo.png");
    max-height: 23px;
  } */

#gft_logo {
  content: url("../../public/GFT_Logo_A2_RGB_web.png");
  border: none;
  max-height: 45px;
}

#app_logo {
  content: url("../../public/GFT_Logo_A2_RGB_web.png");
  border: none!important;
  max-height: 45px;
}